<template>
  <div v-if="listData && listData.length > 0">
    <div class="box-div" v-for="(item,index) in listData" :key="index">
      <div class="title" @click="select(item)">
        <div style="width: 90%;display: flex;align-items: center">
          <div v-html="item.title" style="float:left;">

          </div>
          <van-popover
              v-if="item.tips"
              v-model="item.tipsStatus"
              theme="dark"
              trigger="click"
              placement="bottom"
              style="margin-left: 5px"
          >
            <div style="width: 160px;padding: 15px">
              {{item.tips}}
            </div>
            <template #reference>
              <van-icon name="info" color="#A3A3A3"/>
            </template>
          </van-popover>
        </div>
        <template v-if="type === 'radio'">
          <div v-if="selectValue.includes(item.title)">
            <svg t="1718613548086" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2728" width="20" height="20"><path d="M512 985C250.77 985 39 773.23 39 512S250.77 39 512 39s473 211.77 473 473-211.77 473-473 473z" fill="#2F54EB" p-id="2729"></path><path d="M391.84 648.34l321.887-321.635c15.627-15.615 40.953-15.605 56.568 0.022 15.615 15.627 15.605 40.953-0.022 56.568L419.957 733.338c-15.679 15.666-41.108 15.597-56.7-0.156L201.571 569.845c-15.541-15.7-15.412-41.027 0.288-56.568 15.7-15.541 41.026-15.413 56.568 0.288L391.84 648.34z" fill="#FFFFFF" p-id="2730"></path></svg>
          </div>
          <div v-else>
            <svg t="1718613083329" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1582" width="20" height="20"><path d="M512 1024C229.2224 1024 0 794.7776 0 512 0 229.2224 229.2224 0 512 0c282.7776 0 512 229.2224 512 512 0 282.7776-229.2224 512-512 512z m0-34.133333c263.918933 0 477.866667-213.947733 477.866667-477.866667S775.918933 34.133333 512 34.133333 34.133333 248.081067 34.133333 512s213.947733 477.866667 477.866667 477.866667z" fill="#999999" p-id="1583"></path></svg>
          </div>
        </template>
        <template v-if="type === 'check'">
          <div v-if="selectValue.includes(item.title)">
            <svg t="1718676526517" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10982" width="20" height="20"><path d="M927.71041483-47.22948661H87.80569917a141.39810029 141.39810029 0 0 0-141.39810029 141.39810028V936.19430083a141.39810029 141.39810029 0 0 0 141.39810029 141.39810029h841.31869666a141.39810029 141.39810029 0 0 0 141.39810028-141.39810029V93.46162318a141.39810029 141.39810029 0 0 0-142.81208128-140.69110979z m-43.83341109 331.57854517L376.25782373 794.79620057a35.34952507 35.34952507 0 0 1-24.74466756 10.60485751 35.34952507 35.34952507 0 0 1-26.15864854-10.60485751l-195.83636889-221.28802695a35.34952507 35.34952507 0 1 1 52.3172971-46.66137309l170.38471085 190.18044488 482.16752195-482.16752195a35.34952507 35.34952507 0 1 1 49.4893351 49.4893351z" fill="#2F54EB" p-id="10983"></path></svg>
          </div>
          <div v-else>

            <svg t="1718676443138" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7573" width="20" height="20"><path d="M0.00032 64.06398C0.00032 28.671991 28.480311 0 64.0643 0h895.87172C995.328009 0 1024 28.479991 1024 64.06398v895.87172C1024 995.327689 995.520009 1023.99968 959.93602 1023.99968H64.0643A63.90398 63.90398 0 0 1 0.00032 959.9357V64.06398z m31.99999 0v895.87172A31.90399 31.90399 0 0 0 64.0643 991.99969h895.87172A31.90399 31.90399 0 0 0 992.00001 959.9357V64.06398A31.90399 31.90399 0 0 0 959.93602 31.99999H64.0643A31.90399 31.90399 0 0 0 32.00031 64.06398z" fill="#999999" p-id="7574"></path></svg>
          </div>
        </template>
      </div>
      <div v-if="item.content && item.content !== ''" class="content">
        {{item.content}}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateTreeList",
  props: {
    listData: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    selectValue: {
      type: [Array],
      required: true
    }
  },
  data () {
    return {
      showPopover: false,
    }//dataReturm
  },
  created() {

  },
  methods: {
    select (item) {
      if (this.type === 'radio') {
        this.selectValue = [item.title]
        this.$emit('change', this.selectValue)
      }
      if (this.type === 'check') {
        const index = this.selectValue.findIndex((title) => title === item.title)
        if (index !== -1) {
          this.selectValue.splice(index, 1)
        } else {
          this.selectValue.push(item.title)
        }
        this.$emit('change', this.selectValue)
      }
    }
  }
}
</script>

<style scoped>
.box-div {
  padding: 10px 10px;
  border-bottom: 1px solid #f0f0f0;
}
.title {
  display: flex;
  justify-content: space-between;
}
.content {
  font-size: 13px;
  color: #999999 ;
  margin-top: 5px;
}
</style>